exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-barrierefreiheit-js": () => import("./../../../src/pages/barrierefreiheit.js" /* webpackChunkName: "component---src-pages-barrierefreiheit-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-glossar-js": () => import("./../../../src/pages/glossar.js" /* webpackChunkName: "component---src-pages-glossar-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leitfaden-js": () => import("./../../../src/pages/leitfaden.js" /* webpackChunkName: "component---src-pages-leitfaden-js" */),
  "component---src-pages-termsofuse-js": () => import("./../../../src/pages/termsofuse.js" /* webpackChunkName: "component---src-pages-termsofuse-js" */),
  "component---src-pages-tutorial-js": () => import("./../../../src/pages/tutorial.js" /* webpackChunkName: "component---src-pages-tutorial-js" */),
  "component---src-pages-ueberuns-js": () => import("./../../../src/pages/ueberuns.js" /* webpackChunkName: "component---src-pages-ueberuns-js" */)
}

